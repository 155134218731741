<script setup>
import OThemeSelector from 'o365.vue.components.navbar.ThemeSelector.vue';
const props = defineProps({
    showTitle:{
        type:Boolean,
        default:false
    }
});
</script>

<template>
    <div class="dropdown">
        <a href="#" role="button" id="toolbarUserDropdown" class="nav-link active" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person"></i>
            <span class="ms-2" v-if="showTitle">
                {{ $t("User") }}
            </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end" style="width:200px;" aria-labelledby="toolbarUserDropdown">
            <li><a class="dropdown-item" href="/user">User</a></li>
            <li>
                <OThemeSelector :showTitle="showTitle"></OThemeSelector>
            </li>
            <li><a class="dropdown-item" href="/logout">Log out</a></li>
        </ul>
    </div>
</template>
